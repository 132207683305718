// @ts-nocheck
import {
    isEmptyObject,
    isUndefined,
    isEmptyArray,
    isObject,
    isEmptyString,
    isNonEmptyString,
    isNumber,
    isArray,
} from '@utils/type';
import DateUitl from '@utils/date';
import * as utilLogger from '@utils/logger';
import { DetailBaseApi } from '@service/interface/detail/api.interface';
// import * as logger from '@/utils/logger';

function pushWarnLogger(title: string, error?: any) {
    utilLogger.warn(`【用户行为统计】${title}`, {
        extra: {
            error
        }
    });
}

function zaNotExist() {
    const instanceExist = isUndefined(window.za);
    if (instanceExist) {
        pushWarnLogger('za方法不存在');
    }
    return instanceExist;
}

/**
 * 事件上报
 * @param {String} eventValue 统计名称
 */
export function pushEvent(eventValue: string = '', extpData?: any) {
    if (zaNotExist()) {
      return;
    }
  
    try {
      const eventValueArr = eventValue.split('_');
      window.za('event', eventValueArr[0], eventValueArr[1], eventValueArr[2], extpData);
    } catch (error) {
      utilLogger.warn('【用户行为统计】pushEvent失败', {
        extra: {
          catchError: error,
        },
      });
    }
  }

export function vehicleSearch(extpData) {
    if (zaNotExist() || isEmptyObject(extpData)) {
        return;
    }
    try {
        const days = DateUitl.diff({
            start: extpData.ptime,
            end: extpData.dtime
        }).times;
        window.za('ecc.vehicleSearch', {
            pickup_city_code: extpData.pccode,
            pickup_city_name: extpData.pcname,
            pickup_landmark_name: extpData.plname,
            pickup_landmark_id: extpData.plid,

            dropoff_city_code: extpData.dccode || extpData.pccode,
            dropoff_landmark_id: extpData.dlid || extpData.plid,
            dropoff_city_name: extpData.dcname || extpData.pcname,
            dropoff_landmark_name: extpData.dlname || extpData.plname,

            pickup_date: extpData.ptime,
            dropoff_date: extpData.dtime || extpData.ptime,

            pickup_lat: extpData.plat,
            pickup_lng: extpData.plng,
            dropoff_lat: extpData.dlat || extpData.plat,
            dropoff_lng: extpData.plng || extpData.plng,
            days,
            topic: extpData.topic || 'shortRent'
        });
    } catch (error) {
        pushWarnLogger('ecc.vehicleSearch失败', error);
    }
}

function getCommonCondition(condition) {
    return {
        pickup_city_code: condition.pickup.cityCode,
        pickup_city_name: condition.pickup.cityName,
        pickup_landmark_id: condition.pickup.landmarkId,
        pickup_landmark_name: condition.pickup.landmarkName,
        pickup_lat: condition.pickup.lat,
        pickup_lng: condition.pickup.lng,
        pickup_date: condition.pickup.timeStr,

        dropoff_city_code: condition.dropoff.cityCode || condition.pickup.cityCode,
        dropoff_city_name: condition.dropoff.cityName || condition.pickup.cityName,
        dropoff_landmark_id: condition.dropoff.landmarkId || condition.pickup.landmarkId,
        dropoff_landmark_name: condition.dropoff.landmarkName || condition.pickup.landmarkName,
        dropoff_date: condition.dropoff.timeStr || condition.pickup.timeStr,

        dropoff_lat: condition.dropoff.lat || condition.pickup.lat,
        dropoff_lng: condition.dropoff.lng || condition.pickup.lng,
        topic: condition.topic || 'shortRent'
    };
}

export function vehicleListView(clid, renderCarList, options) {
    if (zaNotExist() || isEmptyArray(renderCarList)) {
        return;
    }

    const {
        condition,
        currentPage = 1,
        pageSize,
        currSelectLeftFilter
    } = options;

    try {
        const days = DateUitl.diff({
            start: condition.pickup.timeStr,
            end: condition.dropoff.timeStr
        }).times;
        renderCarList.forEach((carItem, index) => {
            const extpData: any = getCommonCondition(condition);
            extpData.days = days;
            extpData.item_id = String(carItem.list_id);
            extpData.item_name = carItem.title;
            extpData.brand = carItem.brand || '';
            extpData.index = (index + 1) + ((currentPage - 1) * pageSize);

            if (isObject(currSelectLeftFilter) && !isEmptyString(currSelectLeftFilter.title)) {
                extpData.model = currSelectLeftFilter.title;
            }

            carItem.feature_list.forEach((featureItem) => {
                switch (featureItem.type) {
                    case 'seat':
                        extpData.seat = featureItem.longValue;
                        break;
                    case 'displacement':
                        extpData.displacement = featureItem.longValue;
                        break;
                    case 'transmission':
                        extpData.transmission = featureItem.longValue;
                        break;
                    default:
                        break;
                }
            });

            window.za('ecc.vehicleListView', clid, document.title, extpData);
        });
    } catch (error) {
        pushWarnLogger('ecc.vehicleListView失败', error);
    }
}
export function vehicleListSupplierView(clid, renderCarList, options: any = {}) {
    if (zaNotExist() || isEmptyArray(renderCarList)) {
        return;
    }

    const {
        condition,
        commenVars,
        carData
    } = options;

    try {
        const days = DateUitl.diff({
            start: condition.pickup.timeStr,
            end: condition.dropoff.timeStr
        }).times;

        renderCarList.forEach((carItem, index) => {
            if (isUndefined(carItem.isTest) || !carItem.isTest) {
                const extpData: any = getCommonCondition(condition);
                extpData.days = days;
                extpData.item_id = String(carItem.vehicleId);
                extpData.item_name = carData.title;
                extpData.index = index;
                extpData.ori_price = carItem.oriAvgDailyAmount_real || carItem.oriAvgDailyAmount;
                extpData.price = carItem.avgDailyAmount_real || carItem.avgDailyAmount;
                extpData.total_price = carItem.amount_real || carItem.amount;
                extpData.easyrent = carItem.easyRent ? 1 : 0;
                extpData.currency = 'CNY';

                carItem.groups_filter.forEach((filterItem) => {
                    const filterItemArr = filterItem.split(':');
                    const featureKey = isEmptyString(filterItemArr[0]) ? '' : filterItemArr[0];
                    const featureValue = isEmptyString(filterItemArr[1]) ? '' : filterItemArr[1];
                    switch (featureKey) {
                        case 'seat':
                            extpData.seat = `${featureValue}座`;
                            break;
                        case 'transmission':
                            if (featureValue === '1') {
                                extpData.transmission = '自动';
                            } else {
                                extpData.transmission = '手动';
                            }
                            break;
                        case 'brand':
                            extpData.brand = featureValue;
                            break;
                        case 'energyType':
                            extpData.energy = featureValue;
                            break;
                        default:
                            break;
                    }
                });
                const carAgeArr = carItem.carAgeId.split('|');
                if (!isEmptyString(carAgeArr[0]) && /\d+/.test(carAgeArr[0])) {
                    extpData.year = `${carAgeArr[0].replace('款', '')}款`;
                }
                if (!isEmptyString(carAgeArr[1])) {
                    extpData.displacement = carAgeArr[1];
                }

                if (isObject(commenVars.dealers[carItem.dealerId])) {
                    extpData.supplier_dealer_id = String(carItem.dealerId);
                    extpData.supplier_name = commenVars.dealers[carItem.dealerId].name;
                }

                window.za(
                    'ecc.vehicleListSupplierView',
                    clid,
                    document.title,
                    String(carItem.cid),
                    extpData
                );
            }
        });
    } catch (error) {
        pushWarnLogger('ecc.vehicleListSupplierView失败', error);
    }
}
export function vehicleListSelectItem(clid: string, cid: string, pageTitle: string, options: any = {}) {
    try {
        const {
            condition = {},
            featureList = [],
            carItem,
            title: carName,
            currSelectLeftFilter,
            commenVars,
            indexArr = [],
            renderCarList = [],
            vfilters = {}
        } = options;
        const { pickup, dropoff } = condition;
        const [listIndex, groupsIndex] = indexArr;
        const extp: any = {
            pickup_city_code: pickup?.cityCode,
            pickup_city_name: pickup?.cityName,
            pickup_landmark_name: pickup?.landmarkName,
            pickup_landmark_id: pickup?.landmarkId,
            dropoff_city_code: dropoff?.cityCode,
            dropoff_city_name: dropoff?.cityName,
            dropoff_landmark_id: dropoff?.landmarkId,
            dropoff_landmark_name: dropoff?.landmarkName,
            pickup_date: pickup?.timeStr?.replace(/\//g, '-'),
            dropoff_date: dropoff?.timeStr?.replace(/\//g, '-'),
            pickup_lat: pickup?.lat,
            pickup_lng: pickup?.lng,
            dropoff_lat: dropoff?.lat,
            dropoff_lng: dropoff?.lng,
            days: DateUitl.diff({
                start: condition.pickup.timeStr,
                end: condition.dropoff.timeStr
            }).times,
            topic: condition.topic || 'shortRent',
            item_list_id: clid,
            item_list_name: pageTitle,
            item_cid: cid,
            item_id: carItem.vehicleId,
            item_name: carName,
            index: listIndex,
            // transmission: '自动档',
            // seat: '4座',
            // displacement: '2.0T',
            // model: '经济型',
            model: [],
            // year: carItem.carAgeId,
            supplier_dealer_id: carItem.dealerId,
            supplier_name: commenVars?.dealers?.[carItem.dealerId]?.name,
            easyrent: carItem.easyRent ? 1 : 0,
            service_plus: '',
            currency: 'CNY',
            ori_price: carItem.oriAvgDailyAmount_real || carItem.oriAvgDailyAmount,
            price: carItem.avgDailyAmount_real || carItem.avgDailyAmount,
            total_price: carItem.amount_real || carItem.amount
        };
        // 档位 + 座位
        for (const { type, longValue } of featureList) {
            if (['transmission', 'seat'].includes(type)) {
                extp[type] = longValue;
            }
        }
        // 车辆分类类型
        if (renderCarList.length && listIndex >= 0 && vfilters?.data?.length) {
            const kindCodeList = [renderCarList[listIndex]?.kind_code].flat().filter(id => +id !== 0);// 转为数组,排除特定分类 "全部"
            for (const code of kindCodeList) {
                const target = vfilters.data.find(({ kind_code }) => kind_code === code);
                if (target) {
                    // 多次添加
                    extp.model.push(target.title);
                }
            }
            extp.model = extp.model.join(',');
        }
        // 年款的年份
        if (carItem?.carAgeId) {
            if (carItem.carAgeId?.includes('|')) {
                extp.year = carItem.carAgeId.split('|')[0];
            } else {
                extp.year = carItem.carAgeId;
            }
        }
        // 排量
        if (Array.isArray(carItem.tagsLabel) && isObject(commenVars?.tagDict)) {
            for (const tagId of carItem.tagsLabel) {
                const tagInfo = commenVars?.tagDict?.[tagId] || {};
                if (tagInfo.type === 'feature' && /(L|T)$/.test(tagInfo.text)) {
                    extp.displacement = tagInfo.text;
                    break;
                }
            }
        }
        window.za('ecc.vehicleListSelectItem', clid, pageTitle, cid, extp);
    } catch (error) {
        pushWarnLogger('ecc.vehicleListSelectItem失败', error);
    }

}

export function purchase(orderId) {
    if (zaNotExist() || isEmptyString(orderId)) {
        return;
    }

    try {
        window.za('ecc.purchase', orderId, {});
    } catch (error) {
        pushWarnLogger('ecc.purchase失败', error);
    }
}

export function refund(orderId, itemData) {
    if (zaNotExist() || isEmptyObject(itemData)) {
        return;
    }

    try {
        window.za('ecc.refund', orderId, {
            cancel_reason_code: String(itemData.reasonCode || ''),
            cancel_reason_text: String(itemData.reasonText || ''),
            cancel_reason_content: String(itemData.reasonContent || ''),
            cancel_reason_value: itemData.reasonValue || ''
        });
    } catch (error) {
        pushWarnLogger('ecc.refund失败', error);
    }
}

/**
 * 下单
 * @param clid
 * @param cid
 * @param orderId
 * @param tradeId
 * @param tradeSn
 * @param itemData
 */
export function vehicleCheckout(
    clid: string, cid: string, orderId: string,
    tradeId: string, tradeSn: string, itemData: any,
  ) {
    try {
      window.za(
        'ecc.vehicleCheckout',
        clid,
        cid,
        String(orderId),
        String(tradeId),
        String(tradeSn),
        itemData,
      );
    } catch (error) {
        utilLogger.warn('【用户行为统计】vehicleCheckout失败', {
        extra: {
          catchError: error,
        },
      });
    }
  }
  type extraPropsType = {
    [key: string]: string | number
  };
  /**
 * 车型详情套餐选择
 * @param clid
 * @param cid
 * @param selectedData
 */
export function vehicleItemPackagesSelectItem(clid: string, cid: string, selectedData: any) {
    if (zaNotExist()) {
      return;
    }
  
    try {
      const {
        selectedPackagesItem,
        extras,
        ins,
        shuttleService,
      } = selectedData;
    
      const extraProps: extraPropsType = {};
  
      [].concat((extras), ins).forEach((item: any) => {
        extraProps[`extras_service_${item.code}`] = item.quantity;
      });
  
      if (isObject(shuttleService)) {
        Object.keys(shuttleService).forEach((key: string) => {
          if (isNonEmptyString(shuttleService[key]) || isNumber(shuttleService[key])) {
            extraProps[`extras_service_${key}`] = shuttleService[key];
          }
        });
      }
  
      window.za(
        'ecc.vehicleItemPackagesSelectItem',
        clid,
        cid,
        String(selectedPackagesItem.packagesId),
        selectedPackagesItem.title,
        extraProps,
      );
    } catch (error) {
        utilLogger.warn('【用户行为统计】vehicleItemPackagesSelectItem失败', {
        extra: {
          catchError: error,
        },
      });
    }
  }
  export function pushVehicleItemView(rootStateData: any) {
    if (zaNotExist()) {
      return;
    }
  
    try {
      if (
        !isObject(rootStateData)
        || !isObject(rootStateData.baseInfo)
        || !isObject(rootStateData.packages)
        || isEmptyString(rootStateData.baseInfo.query?.cid)
      ) {
        return;
      }
  
      const {
        baseInfo: {
          data: {
            car, condition,
          },
          dealer,
        },
        packages,
      } = rootStateData;
  
      let transmission = '';
      let seat = '';
      let displacement = '';
      car.featureArr.forEach((featureItem: DetailBaseApi.FeatureItem) => {
        // eslint-disable-next-line default-case
        switch (featureItem.type) {
          case 'transmission':
            transmission = featureItem.longValue;
            break;
          case 'seat':
            seat = featureItem.longValue;
            break;
          case 'displacement':
            displacement = featureItem.longValue;
            break;
        }
      });
  
      let year = '';
      const yearStringPick = car.title.match(/[\d]+款/);
      if (isArray(yearStringPick) && yearStringPick.length > 0) {
        year = yearStringPick[0];
      }
  
      window.za(
        'ecc.vehicleItemView',
        rootStateData.baseInfo?.query?.clid,
        rootStateData.baseInfo?.query?.cid,
        {
          pickup_city_code: condition.pickup.cityCode,
          pickup_city_name: condition.pickup.cityName,
          pickup_landmark_name: condition.pickup.landmarkName,
          pickup_landmark_id: condition.pickup.landmarkId,
          pickup_date: condition.pickup.timeStr,
          pickup_lat: condition.pickup.lat,
          pickup_lng: condition.pickup.lng,
          dropoff_city_code: condition.dropoff.cityCode,
          dropoff_city_name: condition.dropoff.cityName,
          dropoff_landmark_id: condition.dropoff.landmarkId,
          dropoff_landmark_name: condition.dropoff.landmarkName,
          dropoff_date: condition.dropoff.timeStr,
          dropoff_lat: condition.dropoff.lat,
          dropoff_lng: condition.dropoff.lng,
          days: DateUitl.diff({
            start: condition.pickup?.timeStr,
            end: condition.dropoff?.timeStr,
          }).times,
          topic: 'shortRent',
          item_id: String(car?.stdId || ''),
          item_name: car.title,
          transmission,
          seat,
          displacement,
          year,
          supplier_dealer_id: String(dealer?.id || ''),
          supplier_name: dealer?.name,
          easyrent: packages.data?.easyRent ? 1 : 0,
        },
      );
    } catch (error) {
      utilLogger.warn('【用户行为统计】pushVehicleItemView失败', {
        extra: {
          catchError: error,
        },
      });
    }
  }

  /**
 * 车型详情套餐曝光展示
 * @param clid
 * @param cid
 * @param packageListData
 */
export function pushVehicleItemPackagesView(clid: string, cid: string, packageListData: any) {
    if (zaNotExist()) {
      return;
    }
  
    try {
      packageListData.forEach((packageItem: any) => {
        window.za(
          'ecc.vehicleItemPackagesView',
          clid,
          cid,
          String(packageItem.id),
          packageItem.title,
          {
            ori_price: packageItem.originPrice, // originPrice 字段
            price: packageItem.price, // price 字段
            ori_total_price: packageItem.oriTotalPrice, // oriTotalPrice 字段
            total_price: packageItem.totalPrice, // totalPrice 字段
          },
        );
      });
    } catch (error) {
      utilLogger.warn('【用户行为统计】pushVehicleItemPackagesView失败', {
        extra: {
          catchError: error,
        },
      });
    }
  }